import api from "@/api";
import { SessionStorageKeys } from "@/enums/Storage";
import { IUserProfileSessionStorage } from "@/models/User";
import { IImpersonateProfileSessionStorage } from "@/models/Impersonate";
import Storage from "@/plugins/storage";
import AuthService from "@/services/AuthService";

jest.mock("@/services/MyRentalsConfig");
jest.mock("@/api");

describe("AuthService", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
    Object.defineProperty(window, "location", {
      value: { reload: jest.fn(), href: null },
    });
    window.location.pathname = "test";
  });
  describe("isImpersonate", () => {
    it("Should detect that we are not impersonating if the url does not contain 'imp'", () => {
      const isImpersonate = AuthService.isImpersonate();
      expect(isImpersonate).toBeFalsy();
    });

    it("Should detect that we are impersonating if the url contains 'imp'", () => {
      window.location.pathname = "/imp/1/test";
      const isImpersonate = AuthService.isImpersonate();
      expect(isImpersonate).toBeTruthy();
    });
  });
  describe("authGuard", () => {
    it("should return nothing if we are entering a public view and not redirect anywhere", async () => {
      const guardResponse = await AuthService.authGuard(true);
      // Then
      expect(guardResponse).toBe("LOGIN");
      expect(location.href).toBeNull();
    });

    it("should log you out if we are entering a public and there is no token in the cookies", async () => {
      spyOn(Storage, "getCookiesItem").and.returnValue(null);
      spyOn(Storage, "removeSessionStorageItem");
      const guardResponse = await AuthService.authGuard(true);
      // Then
      expect(guardResponse).toBe("LOGIN");
      expect(location.href).toBeNull();
      expect(Storage.removeSessionStorageItem).toBeCalledTimes(2);
    });

    it("should return OK if we are not impersonating and user is already logged in and it's profile is in session storage", async () => {
      // Given
      const expectedToken = "token";
      const expectedProfileSession: IUserProfileSessionStorage = {
        id: 1,
        token: expectedToken,
        firstName: "test",
      };
      spyOn(Storage, "getCookiesItem").and.returnValue(expectedToken);
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      // When
      const guardResponse = await AuthService.authGuard();
      // Then
      expect(guardResponse).toBe("OK");
    });
    it("should return LOGIN and redirect to the /login page if user is not logged in and we are not impersonating  ", async () => {
      // Given
      const expectedToken = null;
      const expectedProfileSession = null;
      spyOn(Storage, "getCookiesItem").and.returnValue(expectedToken);
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      // When
      const guardResponse = await AuthService.authGuard();
      // Then
      expect(location.href).toContain("/login");
      expect(guardResponse).toBe("LOGIN");
    });
    it("should return LOGIN and redirect to the /login page if user is not logged in and we are impersonating  ", async () => {
      // Given
      window.location.pathname = "/imp/1/test";

      const expectedToken = null;
      const expectedProfileSession = null;
      spyOn(Storage, "getCookiesItem").and.returnValue(expectedToken);
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      // When
      const guardResponse = await AuthService.authGuard();
      // Then
      expect(location.href).toContain("/login");
      expect(guardResponse).toBe("LOGIN");
    });
    it("should return PROFILE and retrieve profile if user is logged in but the profile in session storage is null if we are not impersonating", async () => {
      // Given
      const expectedToken = "token";
      const expectedProfileSession = null;
      const expectedRetrievedProfile = {
        id: 1,
        firstName: "test",
      };
      spyOn(Storage, "getCookiesItem").and.returnValue(expectedToken);
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      spyOn(api.auth(), "retrieveProfile").and.returnValue(
        expectedRetrievedProfile
      );
      spyOn(Storage, "setSessionStorageItem");
      // When
      const guardResponse = await AuthService.authGuard();
      // Then
      expect(guardResponse).toBe("PROFILE");
      expect(Storage.setSessionStorageItem).toHaveBeenCalledWith(
        SessionStorageKeys.USER_PROFILE,
        {
          token: expectedToken,
          ...expectedRetrievedProfile,
          timestamp: expect.any(Number),
        }
      );
    });
    it("should return PROFILE and retrieve profile if user is logged in and with profile in session but do not match with the token if we are not impersonating  ", async () => {
      // Given
      const expectedToken = "token";
      const expectedProfileSession: IUserProfileSessionStorage = {
        id: 1,
        token: "differentToken",
        firstName: "test",
      };
      const expectedRetrievedProfile = {
        id: 1,
        firstName: "test",
      };
      spyOn(Storage, "getCookiesItem").and.returnValue(expectedToken);
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      spyOn(api.auth(), "retrieveProfile").and.returnValue(
        expectedRetrievedProfile
      );
      spyOn(Storage, "setSessionStorageItem");
      // When
      const guardResponse = await AuthService.authGuard();
      // Then
      expect(guardResponse).toBe("PROFILE");
      expect(Storage.setSessionStorageItem).toHaveBeenCalledWith(
        SessionStorageKeys.USER_PROFILE,
        {
          token: expectedToken,
          ...expectedRetrievedProfile,
          timestamp: expect.any(Number),
        }
      );
    });
    it("should return PROFILE and retrieve profile if user is logged in and with profile in session but the cookies indicate that it should be updated", async () => {
      // Given
      const expectedToken = "token";
      const lastUpdateProfile = 2;
      const expectedProfileSession: IUserProfileSessionStorage = {
        id: 1,
        token: "token",
        firstName: "test",
        timestamp: 1,
      };
      const expectedRetrievedProfile = {
        id: 1,
        firstName: "test",
      };
      spyOn(Storage, "getCookiesItem").and.returnValues(
        expectedToken,
        lastUpdateProfile
      );
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      spyOn(api.auth(), "retrieveProfile").and.returnValue(
        expectedRetrievedProfile
      );
      spyOn(Storage, "setSessionStorageItem");
      // When
      const guardResponse = await AuthService.authGuard();
      // Then
      expect(guardResponse).toBe("OK");
      expect(Storage.setSessionStorageItem).toHaveBeenCalledWith(
        SessionStorageKeys.USER_PROFILE,
        {
          token: expectedToken,
          ...expectedRetrievedProfile,
          timestamp: expect.any(Number),
        }
      );
    });

    it("should return LOGIN if user is logged in without session profile or logged in with profile in session but do not match with the token but fails to retrieve profile if we are not impersonating  ", async () => {
      // Given
      const expectedToken = "token";
      const expectedProfileSession = null;
      spyOn(Storage, "getCookiesItem").and.returnValue(expectedToken);
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      spyOn(api.auth(), "retrieveProfile").and.throwError("error");
      spyOn(Storage, "setSessionStorageItem");
      spyOn(Storage, "removeSessionStorageItem");
      // When
      const guardResponse = await AuthService.authGuard();
      // Then
      expect(location.href).toContain("/login");
      expect(guardResponse).toBe("LOGIN");
      expect(Storage.removeSessionStorageItem).toHaveBeenCalledWith(
        SessionStorageKeys.USER_PROFILE
      );
      expect(Storage.setSessionStorageItem).not.toHaveBeenCalled();
    });
    it("should return OK if we are impersonating and the profile in the sessionstorage matches the user we want to impersonate and it has been retrieved with the token of the current admin user", async () => {
      window.location.pathname = "/imp/1/test";
      const expectedProfileSession: IImpersonateProfileSessionStorage = {
        id: 1,
        token: "userApiToken",
        tokenOfAdminUser: "admintoken",
        firstName: "test",
      };
      spyOn(Storage, "getCookiesItem").and.returnValue("admintoken");
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      // When
      const guardResponse = await AuthService.authGuard();
      // Then
      expect(guardResponse).toBe("OK");
    });

    it("should properly retrieve both the admin profile and the impersonate profile when we are impersonating with a valid admin user and we haven't got the impersonated profile in the storage", async () => {
      window.location.pathname = "/imp/1/test";

      const profileFromApi = {
        id: 1,
      };
      const adminProfileFromApi = {
        id: 5,
      };
      const admintoken = "adminToken";
      const tokenFromApi = "tokenFromApi";
      spyOn(Storage, "getCookiesItem").and.returnValue(admintoken);
      spyOn(Storage, "getSessionStorageItem").and.returnValues([null]);
      spyOn(Storage, "setSessionStorageItem");

      spyOn(api.auth(), "impersonate").and.returnValue({
        token: tokenFromApi,
      });
      spyOn(api.auth(), "retrieveProfile").and.returnValues(
        adminProfileFromApi,
        profileFromApi
      );

      const guardResponse = await AuthService.authGuard();

      expect(api.auth().impersonate).toHaveBeenCalledWith(
        profileFromApi.id,
        admintoken
      );
      expect(api.auth().retrieveProfile).toHaveBeenCalledWith(admintoken);

      expect(Storage.setSessionStorageItem).toHaveBeenNthCalledWith(
        1,
        SessionStorageKeys.USER_PROFILE,
        {
          token: admintoken,
          ...adminProfileFromApi,
          timestamp: expect.any(Number),
        }
      );

      expect(Storage.setSessionStorageItem).toHaveBeenNthCalledWith(
        2,
        SessionStorageKeys.IMPERSONATE_PROFILE,
        {
          token: tokenFromApi,
          timestamp: expect.any(Number),
          tokenOfAdminUser: admintoken,
          ...profileFromApi,
        }
      );
      expect(guardResponse).toEqual("PROFILE");
    });

    it("should return NOT ALLOWED when we are impersonating with an invalid admin user and we haven't got the impersonated profile in the storage", async () => {
      window.location.pathname = "/imp/1/test";

      const admintoken = "adminToken";
      const tokenFromApi = "tokenFromApi";
      spyOn(Storage, "getCookiesItem").and.returnValue(admintoken);
      spyOn(Storage, "getSessionStorageItem").and.returnValue(null);
      spyOn(Storage, "setSessionStorageItem");

      spyOn(api.auth(), "impersonate").and.returnValue({
        token: tokenFromApi,
      });
      spyOn(api.auth(), "retrieveProfile").and.throwError("error");

      const guardResponse = await AuthService.authGuard();

      expect(guardResponse).toEqual("NOT_ALLOWED");
    });

    it("Should redirect when we want to impersonate but no impesonation is needed", async () => {
      window.location.pathname = "/imp/1/test";
      /* eslint-disable-next-line xss/no-location-href-assign */
      window.location.href = "/imp/1/test";
      const profileFromApi = {
        id: 1,
      };
      const adminProfileFromApi = {
        id: 1,
      };
      const admintoken = "adminToken";
      const tokenFromApi = "tokenFromApi";
      spyOn(Storage, "getCookiesItem").and.returnValue(admintoken);
      spyOn(Storage, "getSessionStorageItem").and.returnValues([null]);
      spyOn(Storage, "setSessionStorageItem");

      spyOn(api.auth(), "impersonate").and.returnValue({
        token: tokenFromApi,
      });
      spyOn(api.auth(), "retrieveProfile").and.returnValues(
        adminProfileFromApi,
        profileFromApi
      );

      await AuthService.authGuard();

      expect(window.location.href).toEqual("/test");
    });
  });

  describe("getAuthToken", () => {
    it("should return auth token if auth guard is OK and we are not impersonating", async () => {
      // Given
      spyOn(AuthService, "authGuard").and.returnValue("OK");
      spyOn(Storage, "getCookiesItem").and.returnValue("userApiToken");
      // When
      const apiToken = await AuthService.getAuthToken();
      // Then
      expect(apiToken).toEqual("userApiToken");
      expect(Storage.getCookiesItem).toHaveBeenCalled();
    });

    it("should return auth token if auth guard is OK and we are impersonating", async () => {
      // Given
      window.location.pathname = "/imp/1/test";
      const impersonateProfile = { token: "impersonateToken" };
      spyOn(AuthService, "authGuard").and.returnValue("OK");
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        impersonateProfile
      );
      // When
      const apiToken = await AuthService.getAuthToken();
      // Then
      expect(apiToken).toEqual("impersonateToken");
    });

    it("should reload the page if auth guard is PROFILE when we are not impersonating", async () => {
      // Given
      spyOn(AuthService, "authGuard").and.returnValue("PROFILE");
      // When
      const apiToken = await AuthService.getAuthToken();
      // Then
      expect(apiToken).toBe(undefined);
      expect(window.location.reload).toHaveBeenCalled();
    });
  });

  describe("currentUser", () => {
    it("should retrieve null if no user in session storage when not impersonating", () => {
      // Given
      spyOn(Storage, "getSessionStorageItem").and.returnValue(null);
      // When
      // Then
      expect(AuthService.currentUser).toEqual(null);
    });
    it("should retrieve null if no user in session storage when  impersonating", () => {
      // Given
      window.location.pathname = "/imp/1/test";

      spyOn(Storage, "getSessionStorageItem").and.returnValue(null);
      // When
      // Then
      expect(AuthService.currentUser).toEqual(null);
    });
    it("should retrieve user from session storage and remove the token when not impersonating", () => {
      // Given
      const expectedProfileSession: IUserProfileSessionStorage = {
        id: 1,
        token: "token",
        firstName: "test",
      };
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      // When
      // Then
      expect(AuthService.currentUser).toEqual(expectedProfileSession);
      expect(AuthService.currentUser).not.toHaveProperty("token");
    });
    it("should retrieve user from session storage and remove the tokens when  impersonating", () => {
      // Given
      window.location.pathname = "/imp/1/test";

      const expectedProfileSession: IImpersonateProfileSessionStorage = {
        id: 1,
        token: "token",
        tokenOfAdminUser: "adminToken",
        firstName: "test",
      };
      spyOn(Storage, "getSessionStorageItem").and.returnValue(
        expectedProfileSession
      );
      // When
      // Then
      expect(AuthService.currentUser).toEqual(expectedProfileSession);
      expect(AuthService.currentUser).not.toHaveProperty("token");
      expect(AuthService.currentUser).not.toHaveProperty("tokenOfAdminUser");
    });
  });
});
